import { TOptionsEvents } from 'keen-slider';

// Specify keen-slider breakpoints using tailwind values instead of
// arbitrary media queries.
//
// NOTE: These should be kept in sync with any changes made to the tailwind
// values.
export function breakpointsForTailwind(
  breakpoints: {
    [key in 'sm' | 'md' | 'lg' | 'xl' | '2xl']: Omit<TOptionsEvents, 'breakpoints'>;
  }
): { [key: string]: Omit<TOptionsEvents, 'breakpoints'> } {
  return {
    '(min-width: 640px)': breakpoints['sm'],
    '(min-width: 768px)': breakpoints['md'],
    '(min-width: 1024px)': breakpoints['lg'],
    '(min-width: 1280px)': breakpoints['xl'],
    '(min-width: 1536px)': breakpoints['2xl'],
  };
}
